// Mutation Types
import * as types from './types';

// Services
import EducationService from '@/services/EducationService.js';

export default {
  async registerCenter ({ commit }, payload) {
    const response = await EducationService.registerCenter(payload);
    commit(types.SET_EDUCATION_CENTER_BY_ID, response.data.data);
  },
  async postNewCourse ({ commit }, payload) {
    const response = await EducationService.postNewCourse(payload);
    commit(types.SET_COURSE_BY_ID, response.data);
  },
  async fetchCoursesEducationCenter ({ commit }, payload) {
    try {
      const response = await EducationService.fetchCoursesEducationCenter(payload);
      commit(types.SET_COURSES_EDUCATION_CENTER, response.data);
      commit(types.SET_TOTAL_COURSES_EDUCATION_CENTER, response.data.count);
    } catch (e) {
      commit(types.SET_COURSES_EDUCATION_CENTER, []);
      commit(types.SET_TOTAL_COURSES_EDUCATION_CENTER, 0);
    }
  },
  async fetchCoursesEducationCenterByHeadquarter ({ commit }, payload) {
    try {
      const response = await EducationService.fetchCoursesEducationCenterByHeadquarter(payload);
      commit(types.SET_COURSES_EDUCATION_CENTER, response.data);
    } catch (e) {
      commit(types.SET_COURSES_EDUCATION_CENTER, []);
    }
  },
  async fetchCourseById ({ commit }, payload) {
    try {
      const response = await EducationService.fetchCourseById(payload);
      commit(types.SET_COURSE_BY_ID, response.data.data);
    } catch (e) {
      commit(types.SET_COURSE_BY_ID, {});
    }
  },
  async registerCenterLogo ({ commit }, payload) {
    await EducationService.registerCenterLogo(payload);
  },
  async updateCourse ({ commit }, payload) {
    const response = await EducationService.updateCourse(payload);
    commit(types.SET_COURSE_BY_ID, response.data);
  },
  async uploadFileCourses (_, payload) {
    await EducationService.uploadFileCourses(payload);
  },
  async savedImageCourse (_, payload) {
    await EducationService.savedImageCourse(payload);
  },
  async savedAvatar (_, payload) {
    await EducationService.savedAvatar(payload);
  },
  async fetchEducationCenterById ({ commit }, _) {
    try {
      const response = await EducationService.fetchEducationCenterById();
      commit(types.SET_EDUCATION_CENTER_BY_ID, response.data);
    } catch (e) {
      commit(types.SET_EDUCATION_CENTER_BY_ID, []);
    }
  },
  async updateEducationCenter ({ commit }, payload) {
    const response = await EducationService.updateEducationCenter(payload);
    commit(types.UPDATE_EDUCATION_CENTER, response.data);
  },
  // async fetchMarketTrendReport ({ commit }, payload) {
  //   return await EducationService.fetchMarketTrendReport(payload);
  // },
  async fetchMarketTrendsJobProfiles ({ commit }, payload) {
    try {
      const response = await EducationService.fetchMarketTrendsJobProfiles(payload);
      commit(types.SET_MARKET_JOB_PROFILES, response.data);
    } catch (e) {
      commit(types.SET_MARKET_JOB_PROFILES, []);
    }
  },
  async fetchMarketTrendsSkills ({ commit }, payload) {
    try {
      const response = await EducationService.fetchMarketTrendsSkills(payload);
      commit(types.SET_MARKET_SKILLS, response.data);
    } catch (e) {
      commit(types.SET_MARKET_SKILLS, []);
    }
  },
  async getMarketHomePageTrendsSkills ({ commit }, _) {
    try {
      const response = await EducationService.getMarketHomePageTrendsSkills();
      commit(types.GET_MARKET_HOMEPAGE_SKILLS, response.data);
    } catch (e) {
      commit(types.GET_MARKET_HOMEPAGE_SKILLS, []);
    }
  },
  async getNotifications ({ commit }, payload) {
    const response = await EducationService.getNotifications(payload);
    commit(types.GET_NOTIFICATIONS_EDUCATION_CENTER, response.data);
  },
  async deleteCourseById (_, payload) {
    await EducationService.deleteCourseById(payload);
  },
  async fetchDataDashboardEducationCenter ({ commit }, _) {
    try {
      const response = await EducationService.fetchDataDashboardEducationCenter();
      commit(types.SET_DATA_DASHBOARD_EDUCATION_CENTER, response.data);
    } catch (e) {
      commit(types.SET_DATA_DASHBOARD_EDUCATION_CENTER, {});
    }
  },
  async fetchLeadCourses ({ commit }, payload) {
    try {
      const response = await EducationService.fetchLeadCourses(payload);
      commit(types.SET_LEAD_COURSES, response.data.results);
      commit(types.SET_TOTAL_LEAD_COURSES, response.data.count);
    } catch (e) {
      commit(types.SET_LEAD_COURSES, {});
      commit(types.SET_TOTAL_LEAD_COURSES, 0);
    }
  },
  async fetchLeadByJobProfile ({ commit }, payload) {
    try {
      const response = await EducationService.fetchLeadByJobProfile(payload);
      commit(types.SET_LEADS_BY_JOB_PROFILE, response.data.results);
      commit(types.SET_TOTAL_LEADS_BY_JOB_PROFILE, response.data.count);
    } catch (e) {
      commit(types.SET_LEADS_BY_JOB_PROFILE, {});
      commit(types.SET_TOTAL_LEADS_BY_JOB_PROFILE, 0);
    }
  },
  async fetchLeadByCurrentJobProfile ({ commit }, payload) {
    try {
      const response = await EducationService.fetchLeadByCurrentJobProfile(payload);
      commit(types.SET_LEADS_BY_CURRENT_JOB_PROFILE, response.data.results);
      commit(types.SET_TOTAL_LEADS_BY_CURRENT_JOB_PROFILE, response.data.count);
    } catch (e) {
      commit(types.SET_LEADS_BY_CURRENT_JOB_PROFILE, {});
      commit(types.SET_TOTAL_LEADS_BY_CURRENT_JOB_PROFILE, 0);
    }
  },
  async fetchManagerCenterById ({ commit }, payload) {
    try {
      const response = await EducationService.fetchManagerCenterById(payload);
      commit(types.SET_MANAGER_CENTER_BY_ID, response.data);
    } catch (e) {
      commit(types.SET_MANAGER_CENTER_BY_ID, {});
    }
  },
  async updateManager ({ commit }, payload) {
    const response = await EducationService.updateManager(payload);
    commit(types.UPDATE_MANAGER_CENTER, response.data);
  },
  async deleteCenter ({ commit }, payload) {
    return await EducationService.deleteCenter(payload);
  },
  async fetchLeadsFavoritesCourses ({ commit }, payload) {
    try {
      const response = await EducationService.fetchLeadsFavoritesCourses(payload);
      commit(types.SET_LEADS_FAVORITES_COURSES, response.data.results);
      commit(types.SET_TOTAL_FAVORITES_COURSES, response.data.count);
    } catch (e) {
      commit(types.SET_LEADS_FAVORITES_COURSES, {});
      commit(types.SET_TOTAL_FAVORITES_COURSES, 0);
    }
  },
  async fetchHeadquarters ({ commit }) {
    try {
      const response = await EducationService.fetchHeadquarters();
      commit(types.SET_HEADQUARTERS, response.data);
      commit(types.SET_TOTAL_HEADQUARTERS, response.data);
    } catch (e) {
      commit(types.SET_HEADQUARTERS, []);
    }
  },
  async saveHeadquarter ({ commit }, payload) {
    await EducationService.saveHeadquarter(payload);
  },
  async updateHeadquarter ({ commit }, payload) {
    await EducationService.updateHeadquarter(payload);
  },
  async changeMainHeadquarter ({ commit }, payload) {
    await EducationService.changeMainHeadquarter(payload);
  },
  async deleteHeadquarter ({ commit }, payload) {
    await EducationService.deleteHeadquarter(payload);
  },
  async disableCourse ({ commit }, payload) {
    await EducationService.disableCourse(payload);
  },
  async activeCourse ({ commit }, payload) {
    await EducationService.activeCourse(payload);
  }
};
