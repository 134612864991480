export const SET_AREAS_KNOWLEDGE = 'SET_AREAS_KNOWLEDGE';
export const SET_TOTAL_AREAS_KNOWLEDGE = 'SET_TOTAL_AREAS_KNOWLEDGE';
export const SET_EMPLOYEES = 'SET_EMPLOYEES';
export const SET_TOTAL_EMPLOYEES = 'SET_TOTAL_EMPLOYEES';
export const SET_EMPLOYEE = 'SET_EMPLOYEE';
export const SET_BRANCH_OFFICE = 'SET_BRANCH_OFFICE';
export const SET_TOTAL_BRANCH_OFFICE = 'SET_TOTAL_BRANCH_OFFICE';
export const SET_JOB_PROFILES = 'SET_JOB_PROFILES';
export const SET_COUNTRIES = 'GET_COUNTRIES';
export const GET_STATES = 'GET_STATES';
export const GET_LOCALITIES = 'GET_LOCALITIES';
export const SET_JOB_POSITIONS = 'SET_JOB_POSITIONS';
export const SET_TOTAL_JOB_PROFILES = 'SET_TOTAL_JOB_PROFILES';
export const SET_JOB_POSITION = 'SET_JOB_POSITION';
export const SET_JOB_EMPLOYEES = 'SET_JOB_EMPLOYEES';
export const SET_RECOMMENDED_JOB_EMPLOYEES = 'SET_RECOMMENDED_JOB_EMPLOYEES';
export const SET_COMPANY = 'SET_COMPANY';
export const GET_MARKET_JOB_PROFILES = 'GET_MARKET_JOB_PROFILES';
export const GET_MARKET_SKILLS = 'GET_MARKET_SKILLS';
export const SET_MANAGER_DATA = 'SET_MANAGER_DATA';
export const SET_COMPANY_DATA = 'SET_COMPANY_DATA';
export const SET_TEAM = 'SET_TEAM';
export const GET_DEFAULT_ROL_PERMISSIONS = 'GET_DEFAULT_ROL_PERMISSIONS';
export const SET_COURSE_DETAIL = 'SET_COURSE_DETAIL';
