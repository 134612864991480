import config from '@/config/config';
import ApiService from '@/services';

export const EducationService = {
  registerCenter (payload) {
    return ApiService.post(`${config.EDUCATION_ENDPOINT}center`, payload);
  },
  registerCenterLogo (payload) {
    return ApiService.put(`${config.EDUCATION_ENDPOINT}center`, payload);
  },
  postNewCourse (payload) {
    return ApiService.post(`${config.EDUCATION_ENDPOINT}course-register`, {
      name: payload.title,
      education_center: payload.education_center,
      type_modality: payload.modality.id,
      code: payload.code,
      range_of_date: payload.range_of_date ?? null,
      start_date: payload.range_of_date[0] ?? null,
      end_date: payload.range_of_date[1] ?? null,
      description: payload.description,
      duration: Number(payload.duration),
      headquarters: payload.headquarters ?? null,
      ects_credits: Number(payload.credits) ?? null,
      price: Number(payload.price),
      url_course: payload.urlCourse,
      hard_skills: payload.hardSkills,
      soft_skills: payload.softSkills,
      course_level: payload.course_level
    });
  },
  fetchCoursesEducationCenter (payload) {
    return ApiService.query(`${config.EDUCATION_ENDPOINT}course-list`, {
      name: payload.name ?? null,
      type_modality: payload.type_modality ?? null,
      min_hour: payload.min_hour ?? null,
      max_hour: payload.max_hour ?? null,
      min_price: payload.min_price ?? null,
      max_price: payload.max_price ?? null,
      offset: payload.offset ?? 0,
      limit: payload.limit ?? 10
    });
  },
  fetchCoursesEducationCenterByHeadquarter (payload) {
    return ApiService.get(
      `${config.EDUCATION_ENDPOINT}course-list/?education_center=${payload}&`
    );
  },
  fetchCourseById (payload) {
    return ApiService.get(`${config.EDUCATION_ENDPOINT}course-info/${payload}`);
  },
  updateCourse (payload) {
    return ApiService.put(
      `${config.EDUCATION_ENDPOINT}course-edit/${payload.id}`,
      {
        name: payload.name,
        education_center: payload.education_center,
        code: payload.code,
        url_course: payload.url_course,
        description: payload.description,
        type_modality: payload.type_modality.id,
        range_of_date: payload.range_of_date,
        duration: payload.duration,
        headquarters: payload.headquarters,
        ects_credits: payload.ects_credits,
        price: payload.price,
        active: payload.active,
        hard_skills: payload.hard_skills,
        soft_skills: payload.soft_skills,
        course_level: payload.course_level
      }
    );
  },
  uploadFileCourses (payload) {
    return ApiService.post(
      `${config.EDUCATION_ENDPOINT}course-import/`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  },
  savedImageCourse (payload) {
    return ApiService.put(
      `${config.EDUCATION_ENDPOINT}course-edit/${payload.id}`,
      payload.url_image.formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  },
  fetchEducationCenterById () {
    return ApiService.get(`${config.EDUCATION_ENDPOINT}center`);
  },
  getCompetencesDemandByAreaOfKnowledge () {
    return ApiService.get(`${config.EDUCATION_ENDPOINT}training-center-chart`);
  },
  savedAvatar (payload) {
    return ApiService.put(
      `${config.EDUCATION_ENDPOINT}center`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  },
  updateEducationCenter (payload) {
    return ApiService.put(`${config.EDUCATION_ENDPOINT}center`, payload);
  },
  updateManager (payload) {
    return ApiService.put(
      `${config.EDUCATION_ENDPOINT}managers/${payload.id}/update`,
      {
        first_name: payload.first_name,
        last_name: payload.last_name
      }
    );
  },
  // fetchMarketTrendReport (payload) {
  //   if (payload) {
  //     return ApiService.query(`${config.CORE_ENDPOINT}market-trend-report`,
  //       {
  //         year_register: payload.year,
  //         country: payload.country,
  //         work_field: payload.work_field,
  //         limit: payload.limit ?? -1,
  //         offset: payload.offset ?? 0
  //       }
  //     );
  //   } else {
  //     return ApiService.query(`${config.CORE_ENDPOINT}market-trend-report`);
  //   }
  // },
  fetchMarketTrendsJobProfiles (payload) {
    if (payload !== undefined) {
      return ApiService.get(
        `${config.DATA_ENDPOINT}market-trend-job-profiles/?${payload}&`
      );
    }
    return ApiService.get(`${config.DATA_ENDPOINT}market-trend-job-profiles`);
  },
  fetchMarketTrendsSkills () {
    return ApiService.get(`${config.DATA_ENDPOINT}market-trend-skills`);
  },
  getMarketHomePageTrendsSkills () {
    return ApiService.get(`${config.CORE_ENDPOINT}homepage-ranking-skills`);
  },
  getNotifications () {
    return ApiService.get(`${config.DATA_ENDPOINT}notifications`);
  },
  deleteCourseById (payload) {
    return ApiService.delete(
      `${config.EDUCATION_ENDPOINT}course-delete/${payload}`
    );
  },
  fetchDataDashboardEducationCenter () {
    return ApiService.get(`${config.EDUCATION_ENDPOINT}dashboard`);
  },
  fetchLeadCourses (payload) {
    return ApiService.query(`${config.EDUCATION_ENDPOINT}lead-list`,
      {
        limit: payload.limit ?? -1,
        offset: payload.offset ?? 0
      });
  },
  fetchLeadByJobProfile (payload) {
    return ApiService.query(`${config.EDUCATION_ENDPOINT}lead-list-by-preferred-job-profile`,
      {
        limit: payload.limit ?? -1,
        offset: payload.offset ?? 0
      });
  },
  fetchLeadByCurrentJobProfile (payload) {
    return ApiService.query(`${config.EDUCATION_ENDPOINT}lead-list-by-current-job-profile`,
      {
        limit: payload.limit ?? -1,
        offset: payload.offset ?? 0
      });
  },
  fetchManagerCenterById (payload) {
    return ApiService.get(`${config.EDUCATION_ENDPOINT}managers/${payload}`);
  },
  fetchLeadsFavoritesCourses (payload) {
    return ApiService.query(`${config.EDUCATION_ENDPOINT}lead-favorite-list`,
      {
        limit: payload.limit ?? -1,
        offset: payload.offset ?? 0
      });
  },
  deleteCenter (payload) {
    return ApiService.delete(`${config.EDUCATION_ENDPOINT}center`, payload);
  },
  saveHeadquarter (payload) {
    return ApiService.post(`${config.EDUCATION_ENDPOINT}headquarter/`, payload);
  },
  fetchHeadquarters () {
    return ApiService.get(`${config.EDUCATION_ENDPOINT}headquarters`);
  },
  updateHeadquarter (payload) {
    return ApiService.put(
      `${config.EDUCATION_ENDPOINT}headquarter/${payload.id}`,
      payload
    );
  },
  changeMainHeadquarter (payload) {
    return ApiService.put(
        `${config.EDUCATION_ENDPOINT}headquarter/${payload.id}`,
        payload.data
    );
  },
  deleteHeadquarter (payload) {
    return ApiService.delete(
      `${config.EDUCATION_ENDPOINT}headquarter/${payload}`
    );
  },
  disableCourse (payload) {
    return ApiService.put(
      `${config.EDUCATION_ENDPOINT}course-edit/${payload}`,
      {
        status: 2
      }
    );
  },
  activeCourse (payload) {
    return ApiService.put(
      `${config.EDUCATION_ENDPOINT}course-edit/${payload}`,
      {
        status: 1
      }
    );
  }
};
export default EducationService;
